import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { Events } from '@ionic/angular';
import { AngularFirestore } from 'angularfire2/firestore';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(
    private storage: Storage,
    private events: Events,
    private authService: AuthService,
    private afs: AngularFirestore
  ) { 
    this.events.subscribe('auth:statusChanged', (user:any)=>{
      if(user){
        this.updateUser(user.uid);
      }else{
        this.updateUser(null);
      }
    });
  }
  prepareUser(user:any){
    if(user){
      let User:any = {
        displayName: user.displayName,
        email: user.email,
        uid: user.uid,
        photoURL: user.photoURL
      };
      return User;
    }else{
      return null;
    }
  }
  updateUser(uid:any){
    return new Promise((resolve) => {
      if(uid){
        this.getByUid(uid).subscribe((user:any)=>{
          this.storage.set('user', JSON.stringify(user[0]));
          this.events.publish('user:logged', user[0]);
          resolve(user[0]);
        });
      }else{
        this.authService.signOut().then(()=>{
          this.storage.set('user', null);
          this.events.publish('user:logged', null);
          resolve(null);
        });
      }
    });
  }
  updateUserDoc(doc:any){
    return new Promise((resolve) => {
      if(doc){
        this.getByDocRef(doc).subscribe((user:any)=>{
          this.storage.set('user', JSON.stringify(user[0]));
          this.events.publish('user:logged', user[0]);
          resolve(user[0]);
        });
      }else{
        this.authService.signOut().then(()=>{
          this.storage.set('user', null);
          this.events.publish('user:logged', null);
          resolve(null);
        });
      }
    });
  }
  getByUid(uid:any){
    return this.afs.collection('users', ref => ref.where('id', '==', uid)).valueChanges();
  }
  getByDocRef(docRef:any){
    return this.afs.collection('users').doc(String(docRef).toString()).valueChanges();
  }
  getUser(){
    return new Promise((resolve) => {
      let User:any = null;
      this.storage.get('user')
      .then((user:any)=>{
        if(user){
          User = JSON.parse(user);
          this.getByUid(User.id).subscribe((user:any)=>{
            resolve(user[0]);
          });
        }else{
          resolve(null);
        }
      });
    });
  }
  getModel(){
    return {
      id: "",
      displayName: "",
      phone_number: "",
      phone_view: true,
      email: "",
      email_view: true,
      gender: "",
      birthdate: "",
      document: "",
      photoUrl: "",
      docRef: ''
    }
  }
  addUser(user:any){
    return new Promise((resolve, reject)=>{
      this.getByUid(user.uid).subscribe((CurrentUser:any)=>{
        if(CurrentUser.length == 0){
          let model = this.getModel();
          model.id = user.uid;
          model.email = user.email;
          this.afs.collection('users').add(model)
          .then((result:any)=>{
            this.updateDocRef(result.id).then(()=>{
              this.getByDocRef(result.id).subscribe((doc:any)=>{
                resolve(doc);
              });
            }).catch((error:any)=>{
              reject(error);
            });
          });
        }else{
          resolve(CurrentUser);
        }
      });
    });
  }
  //UPDATES
  //UPDATES > DOC REF
  updateDocRef(docRef:any){
    return this.afs.collection('users').doc(docRef).update({
      docRef: docRef
    });
  }
  //UPDATES > DISPLAY NAME
  updateDisplayName(docRef:any, displayName:any){
    return this.afs.collection('users').doc(docRef).update({
      displayName: displayName
    });
  }
  //UPDATES > PHOTO URL
  updatePhotoUrl(docRef:any, photoUrl:any){
    return this.afs.collection('users').doc(docRef).update({
      photoUrl: photoUrl
    });
  }
  //UPDATES > GENDER
  updateGender(docRef:any, gender:any){
    return this.afs.collection('users').doc(docRef).update({
      gender: gender
    });
  }
  //UPDATES > BIRTHDATE
  updateBirthDate(docRef:any, birthdate:any){
    return this.afs.collection('users').doc(docRef).update({
      birthdate: birthdate
    });
  }
  //UPDATES > BIRTHDATE
  updateDeviceToken(docRef:any, deviceToken:any){
    return this.afs.collection('users').doc(docRef).update({
      deviceToken: deviceToken
    });
  }
  //UPDATES > DOCUMENT
  updateDocument(docRef:any, document:any){
    return new Promise((resolve, reject)=>{
      let params = {
        document: document
      };
      this.afs.collection('users').doc(docRef).update(params)
      .then(()=>{
        this.getByDocRef(docRef).subscribe((user:any)=>{
          resolve(user);
        });
      }).catch((error:any)=>{
        reject(error);
      });
    });
  }
  //UPDATES > PHONE NUMBER
  updatePhoneNumber(docRef:any, phoneNumer:any){
    return new Promise((resolve, reject)=>{
      let params = {
        phone_number: phoneNumer
      };
      this.afs.collection('users').doc(docRef).update(params)
      .then(()=>{
        this.getByDocRef(docRef).subscribe((user:any)=>{
          resolve(user);
        });
      }).catch((error:any)=>{
        reject(error);
      });
    });
  }
  updatePhoneNumberView(docRef:any, phoneNumerView:any){
    return new Promise((resolve, reject)=>{
      let params = {
        phone_view: phoneNumerView
      };
      this.afs.collection('users').doc(docRef).update(params)
      .then(()=>{
        this.getByDocRef(docRef).subscribe((user:any)=>{
          resolve(user);
        });
      }).catch((error:any)=>{
        reject(error);
      });
    });
  }
  //UPDATES > EMAIL
  updateEmailView(docRef:any, emailView:any){
    return new Promise((resolve, reject)=>{
      let params = {
        email_view: emailView
      };
      this.afs.collection('users').doc(docRef).update(params)
      .then(()=>{
        this.getByDocRef(docRef).subscribe((user:any)=>{
          resolve(user);
        });
      }).catch((error:any)=>{
        reject(error);
      });
    });
  }
  //GET MENU
  getMenu(){
    return new Promise((resolve)=>{
      let valueChanges = this.afs.collection('configurations').doc('menu').valueChanges();
      valueChanges.subscribe((result:any)=>{
        resolve(result);
      });
    });
  }
}
