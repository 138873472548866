import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: '', loadChildren: './pages/home/home.module#HomePageModule' },
  { path: 'login', loadChildren: './pages/login/login.module#LoginPageModule' },
  { path: 'conta/criar/autenticacao', loadChildren: './pages/create-account-auth/create-account-auth.module#CreateAccountAuthPageModule' },
  { path: 'conta/criar/nome', loadChildren: './pages/create-account-name/create-account-name.module#CreateAccountNamePageModule' },
  { path: 'conta/criar/foto', loadChildren: './pages/create-account-photo/create-account-photo.module#CreateAccountPhotoPageModule' },
  { path: 'conta/criar/genero', loadChildren: './pages/create-account-gender/create-account-gender.module#CreateAccountGenderPageModule' },
  { path: 'conta/criar/nascimento', loadChildren: './pages/create-account-birthdate/create-account-birthdate.module#CreateAccountBirthdatePageModule' },
  { path: 'conta/criar/documento', loadChildren: './pages/create-account-document/create-account-document.module#CreateAccountDocumentPageModule' },
  { path: 'conta/criar/telefone', loadChildren: './pages/create-account-phone/create-account-phone.module#CreateAccountPhonePageModule' },
  { path: 'conta/perfil', loadChildren: './pages/account-profile/account-profile.module#AccountProfilePageModule' },
  { path: 'conta/esqueci-minha-senha', loadChildren: './pages/account-forget-password/account-forget-password.module#AccountForgetPasswordPageModule' },
  { path: 'meus-pets/adicionar', loadChildren: './pages/pets-add/pets-add.module#PetsAddPageModule' },
  { path: 'meus-pets', loadChildren: './pages/pets/pets.module#PetsPageModule' },
  { path: 'pet-perdido', loadChildren: './pages/pets-lost/pets-lost.module#PetsLostPageModule' },
  { path: 'address-add', loadChildren: './modals/address-add/address-add.module#AddressAddPageModule' },
  { path: 'pet-photo-add', loadChildren: './modals/pet-photo-add/pet-photo-add.module#PetPhotoAddPageModule' },
  { path: 'pinpoint-add-success', loadChildren: './modals/pinpoint-add-success/pinpoint-add-success.module#PinpointAddSuccessPageModule' },
  { path: 'meus-chamados', loadChildren: './pages/pinpoints/pinpoints.module#PinpointsPageModule' },
  { path: 'pet-adocao', loadChildren: './pages/pets-adoption/pets-adoption.module#PetsAdoptionPageModule' },
  { path: 'desaparecimento/pet', loadChildren: './pages/pets-disappeared/pets-disappeared.module#PetsDisappearedPageModule' },
  { path: 'meus-enderecos', loadChildren: './pages/address/address.module#AddressPageModule' },
  { path: 'desaparecimento/informacoes', loadChildren: './pages/pets-disappeared-info/pets-disappeared-info.module#PetsDisappearedInfoPageModule' },
  { path: 'pet-delete-confirm', loadChildren: './modals/pet-delete-confirm/pet-delete-confirm.module#PetDeleteConfirmPageModule' },
  { path: 'busca', loadChildren: './pages/search/search.module#SearchPageModule' },
  { path: 'meus-chamados/visualizar', loadChildren: './pages/pinpoints-view/pinpoints-view.module#PinpointsViewPageModule' },
  { path: 'search-filter-advanced', loadChildren: './modals/search-filter-advanced/search-filter-advanced.module#SearchFilterAdvancedPageModule' },
  { path: 'carteirinha-vacinacao', loadChildren: './pages/vaccination-card/vaccination-card.module#VaccinationCardPageModule' },
  { path: 'condominio', loadChildren: './pages/condominiums-view/condominiums-view.module#CondominiumsViewPageModule' },
  { path: 'carteirinha-vacinacao/pet', loadChildren: './pages/vaccination-card-pet/vaccination-card-pet.module#VaccinationCardPetPageModule' },
  { path: 'carteirinha-vacinacao/pet/adicionar', loadChildren: './modals/vaccination-card-pet-add/vaccination-card-pet-add.module#VaccinationCardPetAddPageModule' },
  { path: 'ongs/visualizar', loadChildren: './pages/ongs-view/ongs-view.module#OngsViewPageModule' },
  { path: 'ongs-pet-view', loadChildren: './modals/ongs-pet-view/ongs-pet-view.module#OngsPetViewPageModule' },
  { path: 'com/visualizar', loadChildren: './pages/com-view/com-view.module#ComViewPageModule' },
  { path: 'com-pet-view', loadChildren: './modals/com-pet-view/com-pet-view.module#ComPetViewPageModule' },
  { path: 'camera-crop', loadChildren: './modals/camera-crop/camera-crop.module#CameraCropPageModule' }



];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
