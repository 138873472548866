import { Component, OnInit } from '@angular/core';
import { AlertController, LoadingController, ModalController, NavParams, Platform } from '@ionic/angular';
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import { Crop } from '@ionic-native/crop/ngx';
import { File } from '@ionic-native/file/ngx';

//SERVICES
import { AndroidPermissionsService } from '../../services/android-permissions/android-permissions.service';
import { ErrorService } from 'src/app/services/error/error.service';

import 'firebase/storage';
import 'firebase/firestore';
import * as firebase from 'firebase/app';
import * as moment from 'moment';

@Component({
  selector: 'app-camera-crop',
  templateUrl: './camera-crop.page.html',
  styleUrls: ['./camera-crop.page.scss'],
})
export class CameraCropPage implements OnInit {

  selectedPicture:string;
  croppedPicture:string = "";

  pictureUrl:string;

  paramsSource:string;
  paramsCollection:string;

  constructor(
    private alertCtrl: AlertController,
    private androidPermissionsSERVICE: AndroidPermissionsService,
    private camera: Camera,
    private crop: Crop,
    private errorService: ErrorService,
    private file: File,
    private loadingCtrl: LoadingController,
    private modalCtrl: ModalController,
    public navParams: NavParams,
    private platform: Platform
  ) { }

  ngOnInit() {
  
  }

  ionViewWillEnter(){

    //check platform and android permissions
    this.androidPermissionsSERVICE.check();

    //get params
    this.paramsSource = this.navParams.get('source');
    this.paramsCollection = this.navParams.get('collection');

    //validate source to get picture
    if (this.navParams.get('type') == 'camera'){
      this.getByCamera();
    } else {
      this.getByGallery();
    }
    //end validate source to get picture

  }

  //CROP
  cropImage(fileUrl){

    this.crop.crop(fileUrl, { quality: 100 })
    .then(
      newPath => {
        this.convertImage(newPath.split('?')[0])
      },
      error => {
        //alert('Error cropping image' + error);
      }
    );

  }
  //END CROP

  //CONVERT IMAGE
  convertImage(ImagePath){

    var copyPath = ImagePath;
    var splitPath = copyPath.split('/');
    var imageName = splitPath[splitPath.length - 1];
    var filePath = ImagePath.split(imageName)[0];

    this.file.readAsDataURL(filePath, imageName).then(base64 => {
      this.croppedPicture = base64;
      this.photoUpload(base64);
    }, error => {
      //console.log('Error in showing image' + error);
    });

  }
  //END CONVERT IMAGE

  //CAMERA
  getByCamera(){

    const options: CameraOptions = {
      quality: 100,
      destinationType: this.camera.DestinationType.FILE_URI,
      encodingType: this.camera.EncodingType.JPEG,
      targetWidth: 600,
      targetHeight: 600,
      mediaType: this.camera.MediaType.PICTURE,
      correctOrientation: true
    }
    this.camera.getPicture(options).then((imageData) => {
      //let base64Image = 'data:image/jpeg;base64,'+imageData;
      //this.selectedPicture = base64Image;
      this.cropImage(imageData);
     }, (err) => {
      // Handle error
     });

  }
  //END CAMERA

  //GALLERY
  getByGallery(){

    const options: CameraOptions = {
      quality: 100,
      destinationType: this.camera.DestinationType.FILE_URI,
      encodingType: this.camera.EncodingType.JPEG,
      targetWidth: 600,
      targetHeight: 600,
      mediaType: this.camera.MediaType.PICTURE,
      sourceType: this.camera.PictureSourceType.PHOTOLIBRARY,
      correctOrientation: true
    }
    this.camera.getPicture(options).then((imageData) => {
      //let base64Image = 'data:image/jpeg;base64,'+imageData;
      //this.selectedPicture = base64Image;
      this.cropImage(imageData);
     }, (err) => {
      // Handle error
     });

  }
  //END GALLERY

  //GENERATE HASH
  photoGenerateHash() {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < 8; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  //END GENERATE HASH

  //UPLOAD
  async photoUpload(image:string){
    let loading = await this.loadingCtrl.create({
      message: 'Salvando a foto...'
    });
    loading.present();
    this.photoUploadToStorage(image).then((imageUrl:string)=>{
      loading.dismiss();
      this.pictureUrl = imageUrl;
      this.modalCtrl.dismiss({
        url: imageUrl
      });
    }).catch((error:any)=>{
      loading.dismiss();
      this.errorService.firebaseError(error);
    });
  }
  //END UPLOAD
  
  //UPLOAD STORAGE
  photoUploadToStorage(image:string){
    return new Promise((resolve, reject) => {
      let storageRef = firebase.storage().ref(),  
          date = moment(),
          imageName = this.photoGenerateHash()+"-"+date.format('YYYYMMDDHHMMSS'),
          imageRef = storageRef.child(this.paramsCollection).child(imageName);
      imageRef.putString(image, 'data_url')
      .then((result) => {
        resolve(result.ref.getDownloadURL())
      }, error => {
        reject(error);
      });
    });
  }
  //END UPLOAD STORAGE

}
