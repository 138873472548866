import { Component } from '@angular/core';

import { Platform, NavController, MenuController, LoadingController, Events } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { UserService } from './services/user/user.service';
//import { FCM } from '@ionic-native/fcm/ngx';
import { ToastController } from '@ionic/angular'

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {
  user:any = null;

  menuValidation:any = {
    'achei_pet': true,
    'cadastrar_adocao': true,
    'carteirinha_vacinacao': true,
    'informar_desaparecimento': true,
    'meus_chamados': true,
    'meus_enderecos': true,
    'meus_pets': true,
    'minha_conta': true,
    'politica_privacidade': true
  };

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    public navCtrl: NavController,
    private statusBar: StatusBar,
    public menu: MenuController,
    private userService: UserService,
    private loadingCtrl: LoadingController,
    private events: Events,
    //private fcm: FCM,
    private toastCtrl: ToastController
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      this.menu.enable(false, 'first');
      this.userService.getUser().then(async (user: any)=>{
        //let token = await this.fcm.getToken()
        // if(!user.deviceToken || user.deviceToken !== token) {
        //   this.userService.updateDeviceToken(this.user.docRef, token)
        // }
        this.user = user;
      });
      this.events.subscribe('user:logged', (user:any)=>{
        this.user = user;
      });
      // this.fcm.onNotification().subscribe(async data => {
      //   if (data.wasTapped) {
      //     alert(JSON.stringify(data))
      //   } else {
      //     let toast = await this.toastCtrl.create({
      //       message: data.body,
      //       duration: 6000,
      //       position: 'top'
      //     })
      //     toast.present()
      //   };
      // });

      this.getMenuValidation();

      // this.fcm.onTokenRefresh().subscribe(token => {
      //   this.user.messageToken = token
      //   this.userService.updateUserDoc(this.user)
      // });
    });
  }
  subscribeToTopic() {
    //this.fcm.subscribeToTopic('enappd');
  }
  getToken() {
    //this.fcm.getToken().then(token => {
      // Register your new token in your back-end if you want
      // backend.registerToken(token);
    //});
  }
  unsubscribeFromTopic() {
    //this.fcm.unsubscribeFromTopic('enappd');
  }
  //USER
  userMyProfile(){
    this.menu.close('first');
    if(this.user!=null){
      this.navCtrl.navigateForward('conta/perfil');
    }else{
      this.navCtrl.navigateForward('login');
    }
  }
  userMyAddress(){
    this.menu.close('first');
    if(this.user!=null){
      this.navCtrl.navigateForward('meus-enderecos');
    }else{
      this.navCtrl.navigateForward('login');
    }
  }
  userMyPets(){
    this.menu.close('first');
    if(this.user!=null){
      this.navCtrl.navigateForward('meus-pets');
    }else{
      this.navCtrl.navigateForward('login');
    }
  }
  userMyPinpoints(){
    this.menu.close('first');
    if(this.user!=null){
      this.navCtrl.navigateForward('meus-chamados');
    }else{
      this.navCtrl.navigateForward('login');
    }
  }
  async userLogout(){
    let loading = await this.loadingCtrl.create({
      message: 'Saindo...'
    });
    loading.present();
    this.userService.updateUser(null).then(()=>{
      this.user = null;
      loading.dismiss();
      this.menu.close('first');
    })
  }
  userLogin(){
    this.menu.close('first');
    this.navCtrl.navigateForward('login');
  }
  userVaccinationCard(){
    this.menu.close('first');
    if(this.user!=null){
      this.navCtrl.navigateForward('carteirinha-vacinacao');
    }else{
      this.navCtrl.navigateForward('login');
    }
  }
  userCondominiums(){
    this.menu.close('first');
    if(this.user!=null){
      this.navCtrl.navigateForward('condominio');
    }else{
      this.navCtrl.navigateForward('login');
    }
  }
  //PET
  petLost(){
    this.menu.close('first');
    if(this.user!=null){
      this.navCtrl.navigateForward('desaparecimento/pet');
    }else{
      this.navCtrl.navigateForward('login');
    }
  }
  petFindLost(){
    this.menu.close('first');
    if(this.user!=null){
      this.navCtrl.navigateForward('pet-perdido');
    }else{
      this.navCtrl.navigateForward('login');
    }
  }
  petAdoption(){
    this.menu.close('first');
    if(this.user!=null){
      this.navCtrl.navigateForward('pet-adocao');
    }else{
      this.navCtrl.navigateForward('login');
    }
  }

  //GET MENU VALIDATION
  getMenuValidation(){

    this.userService.getMenu()
    .then((result:any) => {
      this.menuValidation = result;
    });

  }
  //END GET MENU VALIDATION
  
}
