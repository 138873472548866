import { Component, OnInit, Input } from '@angular/core';
import { Components } from '@ionic/core';
import { Platform } from '@ionic/angular';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';

@Component({
  selector: 'app-pinpoint-add-success',
  templateUrl: './pinpoint-add-success.page.html',
  styleUrls: ['./pinpoint-add-success.page.scss'],
})
export class PinpointAddSuccessPage implements OnInit {
  @Input() modal: Components.IonModal;
  constructor(
    private platform: Platform,
    private socialSharing: SocialSharing
  ) { }

  ngOnInit() {
  }
  back(){
    this.modal.dismiss();
  }

  share(){
    this.socialSharing.share(null,null,null,'https://petspotapp.com');
  }
}
