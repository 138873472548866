import { Component, OnInit, Input } from '@angular/core';
import { NavController, ActionSheetController, NavParams, AlertController } from '@ionic/angular';
import { Pets } from 'src/app/models/pets';
import { Components } from '@ionic/core';
import { BreedsService } from 'src/app/services/breeds/breeds.service';

@Component({
  selector: 'app-search-filter-advanced',
  templateUrl: './search-filter-advanced.page.html',
  styleUrls: ['./search-filter-advanced.page.scss'],
})
export class SearchFilterAdvancedPage implements OnInit {
  pet:any = new Pets();
  filter:any = {
    type: null,
    breed: null,
    gender: null,
    eyesColors: [],
    eyes: [],
    fur: null
  };
  breeds:any=[];
  @Input() modal: Components.IonModal;
  constructor(
    private navParams: NavParams,
    private alertCtrl:AlertController,
    private breedsService:BreedsService
  ) { 
    this.filter = this.navParams.get('filter');
  }

  ngOnInit() {
    this.breedsGet();
  }
  ionModalWillDismiss(){
  }
  ionViewWillEnter(){
  }
  //BREEDS
  breedsGet(){
    this.breedsService.getAll().subscribe((breeds:any)=>{
      this.breeds = breeds;
    });
  }
  breedGetOptions(){
    let result = [],
        options = [];
    this.breeds.forEach((item:any)=>{
      if(this.filter.type == 'Gato'){
        if(item.type == 'cat'){
          options.push({
            name: item.id,
            type: 'radio',
            label: item.name,
            checked: false,
            value: item.name
          });
        }
      } else if (this.filter.type == "Cachorro"){
        if(item.type == 'dog'){
          options.push({
            name: item.id,
            type: 'radio',
            label: item.name,
            checked: false,
            value: item.name
          });
        }
      }
    });
    options.forEach((option:any)=>{
      if(option.value == this.filter.breed){
        option.checked = true;
      }
      result.push(option);
    });
    result= result.sort((a, b) => {
      if (a.label > b.label) {
        return 1;
      }
      if (a.label < b.label) {
        return -1;
      }
      return 0;
    });
    let srd = result.find(x => x.label === 'Sem raça definida')
    result.splice(result.indexOf(srd), 1)
    result.unshift(srd)
    return result;
  }
  async breedSelect(){
    let alert = await this.alertCtrl.create({
      header: 'Raças',
      subHeader: 'Selecione uma das raças abaixo:',
      inputs: this.breedGetOptions(),
      buttons: [
        {
          text: 'Cancelar'
        },
        {
          text: 'Confirmar',
          handler: async (data) =>{
            this.filter.breed = data;
          }
        }
      ]
    });
    await alert.present();
  }
  //TYPE
  petTypeChanged(type:any){
    this.filter.type = type;
  }
  //SIZE
  petSizeConvertSizeNumberToSizeText(size:any){
    let result = '';
    switch(size){
      case 1:
        result = 'P';
        break;
      case 2:
        result = 'M';
        break;
      case 3:
        result = 'G';
        break;
    }
    return result;
  }
  //SEX
  petSexChanged(sex:any){    
    this.filter.sex = sex;
  }
  //COLORS
  petColorsGetOptions(){
    return [
      'Amarelo',
      'Beje',
      'Branco',
      'Cinza',
      'Malhado',
      'Marrom',
      'Preto',
      'Tigrado'
    ];
  }
  petColorsInputChanged(color:any){
    let has  = false,
        pos  = 0;
    this.filter.colors.forEach((item:any, index:any)=>{
      if(color == item){
        pos = index;
        has = true;
      }
    });
    if(has){
      this.filter.colors.splice(pos, 1);
    }else{
      this.filter.colors.push(color);
    }
  }
  petColorsHasInMyPet(color:any){
    if(this.filter.colors.length > 0){
      let has = false;
      this.filter.colors.forEach((item:any)=>{
        if(color == item){
          has = true;
        }
      });
      return has;
    }else{
      return false;
    }
  }
  //EYES COLORS
  petEyesColorsGetOptions(){
    return [
      'Amarelos',
      'Azuis',
      'Castanhos',
      'Pretos',
      'Verdes',
      'Heterocromia'
    ];
  }
  petEyesColorsInputChanged(color:any){
    let has  = false,
        pos  = 0;
    this.filter.eyesColors.forEach((item:any, index:any)=>{
      if(color == item){
        pos = index;
        has = true;
      }
    });
    if(has){
      this.filter.eyesColors.splice(pos, 1);
    }else{
      this.filter.eyesColors.push(color);
    }
  }
  petEyesColorsHasInMyPet(color:any){
    if(this.filter.eyesColors.length > 0){
      let has = false;
      this.filter.eyesColors.forEach((item:any)=>{
        if(color == item){
          has = true;
        }
      });
      return has;
    }else{
      return false;
    }
  }
  //FUR
  petFurChanged(fur:any){
    this.filter.fur = fur;
  }
  //READY FOR SAVE
  petReadyForSave(){
    return this.petValidateData();
  }
  //VALIDATE DATA
  petValidateData(){
    if(this.pet.type != ''){
      if(this.pet.photos.length > 0){
        if(this.pet.name != '' && this.pet.name != null){
          if(this.pet.breed != '' && this.pet.breed != null){
            if(this.pet.sex != ''){
              if(this.pet.age > 0){
                if(this.pet.size.value > 0){
                  if(this.pet.colors.length > 0){
                    if(this.pet.eyesColors.length > 0){
                      if(this.pet.fur != ''){
                        if(this.pet.address != null){
                          return true;
                        }else{
                          return false;
                        }
                      }else{
                        return false;
                      }
                    }else{
                      return false;
                    }
                  }else{
                    return false;
                  }
                }else{
                  return false;
                }
              }else{
                return false;
              }
            }else{
              return false;
            }
          }else{
            return false;
          }
        }else{
          return false;
        }
      }else{
        return false;
      }
    }else{
      return false;
    }
  }
  //AGE
  petAgeChanged(event:any){
    if(event.detail.value == 20){
      this.pet.age = event.detail.value+'+';
    }else{
      this.pet.age = String(event.detail.value);
    }
  }
  //SAVE
  async petSave(){
    this.modal.dismiss({
      filter: this.filter
    });
  }

}
