import { Injectable } from '@angular/core';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AndroidPermissionsService {

  constructor(
    private androidPermissions: AndroidPermissions,
    public platform: Platform
  ) { }

  check(){

    //validate platform
    if (this.platform.is("android")){

      //check CAMERA permission
      this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.CAMERA).then(
        result => console.log('Has permission?',result.hasPermission),
        err => this.androidPermissions.requestPermissions([this.androidPermissions.PERMISSION.CAMERA])
      );
      //end check CAMERA permission

      //check READ EXTERNAL STORAGE permission
      this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.READ_EXTERNAL_STORAGE).then(
        result => console.log('Has permission?',result.hasPermission),
        err => this.androidPermissions.requestPermissions([this.androidPermissions.PERMISSION.READ_EXTERNAL_STORAGE])
      );
      //end check READ EXTERNAL STORAGE permission

      //check WRITE EXTERNAL STORAGE permission
      this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE).then(
        result => console.log('Has permission?',result.hasPermission),
        err => this.androidPermissions.requestPermissions([this.androidPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE])
      );
      //end check WRITE EXTERNAL STORAGE permission

      //check MANAGE DOCUMENTS permission
      this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.MANAGE_DOCUMENTS).then(
        result => console.log('Has permission?',result.hasPermission),
        err => this.androidPermissions.requestPermissions([this.androidPermissions.PERMISSION.MANAGE_DOCUMENTS])
      );
      //end check MANAGE DOCUMENTS permission

      this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.DEFAULT_SCOPED_STORAGE).then(
        result => console.log('Has permission?',result.hasPermission),
        err => this.androidPermissions.requestPermissions([this.androidPermissions.PERMISSION.DEFAULT_SCOPED_STORAGE])
      );

      this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.FORCE_ENABLE_SCOPED_STORAGE).then(
        result => console.log('Has permission?',result.hasPermission),
        err => this.androidPermissions.requestPermissions([this.androidPermissions.PERMISSION.FORCE_ENABLE_SCOPED_STORAGE])
      );

      this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.RESTRICT_STORAGE_ACCESS_FRAMEWORK).then(
        result => console.log('Has permission?',result.hasPermission),
        err => this.androidPermissions.requestPermissions([this.androidPermissions.PERMISSION.RESTRICT_STORAGE_ACCESS_FRAMEWORK])
      );

      this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.ACTION_OPEN_DOCUMENT_TREE).then(
        result => console.log('Has permission?',result.hasPermission),
        err => this.androidPermissions.requestPermissions([this.androidPermissions.PERMISSION.ACTION_OPEN_DOCUMENT_TREE])
      );

      this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.WRITE_MEDIA_STORAGE).then(
        result => console.log('Has permission?',result.hasPermission),
        err => this.androidPermissions.requestPermissions([this.androidPermissions.PERMISSION.WRITE_MEDIA_STORAGE])
      );

      //request permissions
      this.androidPermissions.requestPermissions([this.androidPermissions.PERMISSION.CAMERA, this.androidPermissions.PERMISSION.READ_EXTERNAL_STORAGE, this.androidPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE,this.androidPermissions.PERMISSION.MANAGE_DOCUMENTS,this.androidPermissions.PERMISSION.DEFAULT_SCOPED_STORAGE,this.androidPermissions.PERMISSION.FORCE_ENABLE_SCOPED_STORAGE,this.androidPermissions.PERMISSION.RESTRICT_STORAGE_ACCESS_FRAMEWORK,this.androidPermissions.PERMISSION.ACTION_OPEN_DOCUMENT_TREE,this.androidPermissions.PERMISSION.WRITE_MEDIA_STORAGE]);

    }
    //end validate platform

  }
}
