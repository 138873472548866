import { Injectable } from "@angular/core";
import { AngularFirestore } from "angularfire2/firestore";
import * as Firebase from "firebase";

@Injectable({
  providedIn: "root",
})
export class PinPointsService {
  private collection: string = "pinpoints";
  constructor(private afs: AngularFirestore) {}
  getByUser(user: any) {
    return new Promise((resolve) => {
      let valueChanges = this.afs
        .collection(this.collection, (ref) =>
          ref.where("user", "==", String(user).toString())
        )
        .valueChanges();
      valueChanges.subscribe((result: any) => {
        resolve(result);
      });
    });
  }
  filter(filter: any) {
    return new Promise((resolve) => {
      let valueChanges = this.afs
        .collection(this.collection, (ref) => {
          let query:
            | firebase.firestore.CollectionReference
            | firebase.firestore.Query = ref;
          if (filter.type != null) {
            query = query.where("data.type", "==", filter.type);
          }
          if (filter.breed != null) {
            query = query.where("data.breed", "==", filter.breed);
          }
          if (filter.gender != null) {
            query = query.where("data.gender", "==", filter.gender);
          }
          if (filter.sex != null) {
            query = query.where("data.sex", "==", filter.sex);
          }
          if (filter.colors.length > 0) {
            query = query.where("data.colors", "array-contains", filter.colors);
          }
          if (filter.eyesColors.length > 0) {
            query = query.where(
              "data.eyesColors",
              "array-contains",
              filter.eyesColors
            );
          }
          if (filter.fur != null) {
            query = query.where("data.fur", "==", filter.fur);
          }
          query = query.where("docRef", ">", "");
          return query;
        })
        .valueChanges();
      valueChanges.subscribe((result: any) => {
        resolve(result);
      });
    });
  }
  syncDocRef(docRef: any) {
    return this.afs
      .collection(this.collection)
      .doc(docRef)
      .update({ docRef: docRef });
  }
  getByDoc(doc: any) {
    return this.afs.collection(this.collection).doc(doc).valueChanges();
  }
  async load() {
    // async load(minLat?, minLng?, maxLat?, maxLng?){
    let finalArray = [];
    // let lngArray = [];
    await Firebase.firestore()
      .collection(this.collection)
      // .where("data.address.lng", ">", minLng)
      // .where("data.address.lng", "<", maxLng)
      .get()
      .then((result) => {
        result.forEach((item) => {
          finalArray.push(item.data());
        });
      });
    // await Firebase.firestore()
    //   .collection(this.collection)
    //   .where("data.address.lat", ">", minLat)
    //   .where("data.address.lat", "<", maxLat)
    //   .get()
    //   .then((result) => {
    //     result.forEach((item) => {
    //       if (lngArray.find((x) => x.docRef === item.data().docRef))
    //         finalArray.push(item.data());
    //     });
    //   });
    //console.log(finalArray.length);
    return finalArray;
    // return new Promise((resolve)=>{
    //   let finalArray = []
    //   let valueChanges = this.afs.collection(this.collection, ref => ref
    //     .where('data.address.lng', '>', minLng)
    //     .where('data.address.lng', '<', maxLng)
    //   ).valueChanges();
    //   valueChanges.subscribe((result:any)=>{
    //     finalArray = result.data()
    //     // this.afs.collection(this.collection, ref => ref
    //     //   .where('data.address.lng', '<', minLng)
    //     //   .where('data.address.lng', '>', maxLng)
    //     // ).valueChanges().subscribe((resultLng:any) => {
    //     //   resultLng.forEach(element => {
    //     //     if(result.find(x => x.docRef === element.docRef)){
    //     //       console.log(element)
    //     //       finalArray.push(element)
    //     //     }
    //     //   });
    //     // })
    //   });
    //   console.log('final: ', finalArray)
    //   resolve(finalArray);
    // });
    // return this.afs.collection(this.collection, ref => ref.where('docRef', '>', '')).valueChanges();
  }
  save(model: any) {
    return new Promise((resolve, reject) => {
      this.afs
        .collection(this.collection)
        .add(model)
        .then((result: any) => {
          this.syncDocRef(result.id)
            .then(() => {
              this.getByDoc(result.id).subscribe((pet: any) => {
                resolve(pet);
              });
            })
            .catch((error: any) => {
              reject(error);
            });
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  update(model: any,doc: any) {
    return new Promise((resolve, reject) => {
      this.afs
        .collection(this.collection)
        .doc(doc)
        .update(model)
        .then((result: any) => {
          this.afs
            .collection(this.collection)
            .doc(doc)
            .update({docRef:doc})
            .then((result: any) => {
              this.syncDocRef(doc)
                .then(() => {
                  this.getByDoc(doc).subscribe((pet: any) => {
                    resolve(pet);
                  });
                })
                .catch((error: any) => {
                  reject(error);
                });
                })
            .catch((error: any) => {
              reject(error);
            });
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  deleteByDocRef(doc: any) {
    return new Promise((resolve, reject) => {
      this.afs
        .collection(this.collection)
        .doc(doc)
        .delete()
        .then(() => {
          resolve();
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  done(docRef: any) {
    return new Promise((resolve, reject) => {
      let params = {
        done: true,
      };
      this.afs
        .collection(this.collection)
        .doc(docRef)
        .update(params)
        .then(() => {
          resolve();
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
}
