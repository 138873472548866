import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { LocalNotifications } from '@ionic-native/local-notifications/ngx';
//import { FCM } from '@ionic-native/fcm/ngx'

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AngularFireModule } from '@angular/fire';
import { AngularFireStorageModule } from 'angularfire2/storage';
import { environment } from '../environments/environment';
import { FirestoreSettingsToken } from 'angularfire2/firestore';
import { AngularFireAuthModule } from 'angularfire2/auth';
import { AngularFirestoreModule } from 'angularfire2/firestore';
import { AngularFireDatabaseModule } from 'angularfire2/database';
import { IonicStorageModule } from '@ionic/storage';
import { BrMaskerModule } from 'brmasker-ionic-3';
import { Facebook } from '@ionic-native/facebook/ngx';
import { AddressAddPageModule } from './modals/address-add/address-add.module';
import { HttpClientModule } from "@angular/common/http";
import { PetPhotoAddPageModule } from './modals/pet-photo-add/pet-photo-add.module';
import { PinpointAddSuccessPageModule } from './modals/pinpoint-add-success/pinpoint-add-success.module';
import { PetDeleteConfirmPageModule } from './modals/pet-delete-confirm/pet-delete-confirm.module';
import { ClickOutsideModule } from 'ng-click-outside';
import { PinpointsViewPageModule } from './pages/pinpoints-view/pinpoints-view.module';
import { SearchFilterAdvancedPageModule } from './modals/search-filter-advanced/search-filter-advanced.module';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { Camera } from '@ionic-native/camera/ngx';
import { VaccinationCardPetAddPageModule } from './modals/vaccination-card-pet-add/vaccination-card-pet-add.module';
import { OngsPetViewPageModule } from './modals/ongs-pet-view/ongs-pet-view.module';
import { CondominiumsPetViewPageModule } from './modals/condominiums-pet-view/condominiums-pet-view.module';
import { ComPetViewPageModule } from './modals/com-pet-view/com-pet-view.module';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { CameraCropPageModule } from './modals/camera-crop/camera-crop.module';
import { Crop } from '@ionic-native/crop/ngx';
import { File } from '@ionic-native/file/ngx';

//SERVICES
import { AddressService } from './services/address/address.service';
import { AuthService } from './services/auth/auth.service';
import { AndroidPermissionsService } from './services/android-permissions/android-permissions.service';
import { ErrorService } from './services/error/error.service';
import { PetsService } from './services/pets/pets.service';
import { PinPointsService } from './services/pinpoints/pin-points.service';
import { UserService } from './services/user/user.service';
import { ValidationService } from './services/validation/validation.service';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.prod), //Change dev to production to build a production environment
    AngularFireAuthModule,
    AngularFirestoreModule.enablePersistence(),
    AngularFireStorageModule,
    AngularFireDatabaseModule,
    IonicStorageModule.forRoot({
      name: '_petspotdb',
         driverOrder: ['indexeddb', 'sqlite', 'websql']
    }),
    BrMaskerModule,
    AddressAddPageModule,
    PetPhotoAddPageModule,
    PinpointAddSuccessPageModule,
    PetDeleteConfirmPageModule,
    PinpointsViewPageModule,
    SearchFilterAdvancedPageModule,
    VaccinationCardPetAddPageModule,
    OngsPetViewPageModule,
    CondominiumsPetViewPageModule,
    ComPetViewPageModule,
    HttpClientModule,
    ClickOutsideModule,
    CameraCropPageModule
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: FirestoreSettingsToken, useValue: {} },
    ValidationService,
    ErrorService,
    AddressService,
    AuthService,
    UserService,
    Facebook,
    PetsService,
    PinPointsService,
    Geolocation,
    AngularFireStorageModule,
    Camera,
    Crop,
    File,
    //FCM,
    SocialSharing,
    AndroidPermissions,
    ValidationService,
    LocalNotifications
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
